var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outerCont",style:('height:' + _vm.height + 'px')},[_c('div',{staticClass:"bigPictrue",on:{"mouseenter":function($event){return _vm.enters()},"mouseleave":function($event){return _vm.leaver()}}},[_c('img',{attrs:{"src":require("@/assets/img/registerLogo.jpg"),"alt":""}}),(_vm.isHover == true)?_c('div',{staticClass:"itemHoverCont",on:{"click":function($event){return _vm.goIndex()}}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "zh" ? "返回首页" : "Return to homepage")+" ")]):_vm._e()]),(_vm.type == 1)?_c('div',{staticClass:"outer"},[_c('div',{staticClass:"tipOne"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "zh" ? "登录" : "Sign in")+" ")]),_c('div',[_c('div',{staticClass:"input"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/name.png"),"alt":""}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.account),expression:"account"}],attrs:{"type":"text","placeholder":_vm.$i18n.locale == 'zh'
            ? '请输入您的账号'
            : 'Please enter your account number'},domProps:{"value":(_vm.account)},on:{"input":function($event){if($event.target.composing)return;_vm.account=$event.target.value}}})]),_c('div',{staticClass:"input"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/password.png"),"alt":""}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","placeholder":_vm.$i18n.locale == 'zh' ? '请输入密码' : 'Please input a password'},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('div',{staticClass:"input inputTwo"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/verificationCode.png"),"alt":""}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.captcha),expression:"captcha"}],attrs:{"type":"text","placeholder":_vm.$i18n.locale == 'zh'
            ? '请输入验证码'
            : 'Please enter the verification code'},domProps:{"value":(_vm.captcha)},on:{"input":function($event){if($event.target.composing)return;_vm.captcha=$event.target.value}}}),_c('div',{on:{"click":_vm.refreshCode}},[_c('identify',{attrs:{"identifyCode":_vm.identifyCode}})],1)]),_c('div',{staticClass:"signIn",on:{"click":function($event){return _vm.signInLogin(1)}}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "zh" ? "登录" : "Sign in")+" ")]),_c('div',{staticClass:"signIn signInTwo",on:{"click":function($event){return _vm.register(2)}}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "zh" ? "注册" : "register")+" ")])])]):_vm._e(),(_vm.type == 2)?_c('div',{staticClass:"outer"},[_c('div',{staticClass:"tipOne"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "zh" ? "注册" : "register")+" ")]),_c('div',[_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"text","placeholder":_vm.$i18n.locale == 'zh'
            ? '请输入您的邮箱（作为登录账号）'
            : 'Please enter your email(As login account)'},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","placeholder":_vm.$i18n.locale == 'zh' ? '请输入密码' : 'Please input a password'},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordtwo),expression:"passwordtwo"}],attrs:{"type":"password","placeholder":_vm.$i18n.locale == 'zh'
            ? '请确认密码'
            : 'Please confirm the password'},domProps:{"value":(_vm.passwordtwo)},on:{"input":function($event){if($event.target.composing)return;_vm.passwordtwo=$event.target.value}}})]),_c('div',{staticClass:"signIn",on:{"click":function($event){return _vm.signInLogin(2)}}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "zh" ? "确认提交" : "Confirm submission")+" ")])])]):_vm._e(),(_vm.type == 3)?_c('div',{staticClass:"outer"},[_c('div',{staticClass:"tipOne"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "zh" ? "完善资料" : "Modify data")+" ")]),_c('div',[_c('div',{staticClass:"tabNav"},_vm._l((_vm.$t('register.tabbar')),function(item,index){return _c('div',{key:index,staticClass:"navItem",on:{"click":function($event){return _vm.navChange(index)}}},[(_vm.navIndex == index)?_c('img',{attrs:{"src":require("@/assets/img/xuanzhong.png"),"alt":""}}):_vm._e(),(_vm.navIndex != index)?_c('img',{attrs:{"src":require("@/assets/img/weixuanzhong.png"),"alt":""}}):_vm._e(),_c('div',[_vm._v(_vm._s(item))])])}),0),_c('div',{staticClass:"identity"},_vm._l((_vm.userCard),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.identityChange(index)}}},[_c('img',{attrs:{"src":_vm.identityIndex == index ? require('@/assets/img/xuanzhong.png') : require('@/assets/img/weixuanzhonglan.png'),"alt":""}}),_c('div',[_vm._v(_vm._s(item.card_name))])])}),0),_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.account),expression:"account"}],attrs:{"type":"text","placeholder":_vm.$i18n.locale == 'zh' ? '***先生/女士' : '***Sir/Madam'},domProps:{"value":(_vm.account)},on:{"input":function($event){if($event.target.composing)return;_vm.account=$event.target.value}}})]),_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"text","placeholder":_vm.$i18n.locale == 'zh'
            ? '请输入您的邮箱（作为登录账号）'
            : 'Please enter your email(As login account)'},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","placeholder":_vm.$i18n.locale == 'zh' ? '请输入密码' : 'Please input a password'},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordtwo),expression:"passwordtwo"}],attrs:{"type":"password","placeholder":_vm.$i18n.locale == 'zh'
            ? '请确认密码'
            : 'Please confirm the password'},domProps:{"value":(_vm.passwordtwo)},on:{"input":function($event){if($event.target.composing)return;_vm.passwordtwo=$event.target.value}}})]),_c('div',{staticClass:"signInBox"},[_c('div',{staticClass:"signIn signInTwo",on:{"click":function($event){return _vm.goIndex()}}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "zh" ? "返回首页" : "Return to homepage")+" ")]),_c('div',{staticClass:"signIn",on:{"click":function($event){return _vm.signInLogin(3)}}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "zh" ? "确认完善" : "Confirm improvement")+" ")])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }