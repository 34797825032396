<template>
  <div class="outerCont" :style="'height:' + height + 'px'">
    <div class="bigPictrue" @mouseenter="enters()" @mouseleave="leaver()">
      <img src="@/assets/img/registerLogo.jpg" alt="" />
      <div class="itemHoverCont" v-if="isHover == true" @click="goIndex()"> {{ $i18n.locale == "zh" ? "返回首页" : "Return to homepage" }}  </div>
    </div>
    <!-- 登录 -->
    <div class="outer" v-if="type == 1">
      <div class="tipOne"> {{ $i18n.locale == "zh" ? "登录" : "Sign in" }} </div>
      <div>
        <div class="input">
          <img src="@/assets/img/name.png" alt="" class="icon" />
          <input type="text" :placeholder="
            $i18n.locale == 'zh'
              ? '请输入您的账号'
              : 'Please enter your account number'
          " v-model="account" />
        </div>
        <div class="input">
          <img src="@/assets/img/password.png" alt="" class="icon" />
          <input type="password" :placeholder="
            $i18n.locale == 'zh' ? '请输入密码' : 'Please input a password'
          " v-model="password" />
        </div>
        <div class="input inputTwo">
          <img src="@/assets/img/verificationCode.png" alt="" class="icon" />
          <input type="text" :placeholder="
            $i18n.locale == 'zh'
              ? '请输入验证码'
              : 'Please enter the verification code'
          " v-model="captcha" />
          <div @click="refreshCode">
            <identify :identifyCode="identifyCode"></identify>
          </div>
        </div>
        <div class="signIn" @click="signInLogin(1)"> {{ $i18n.locale == "zh" ? "登录" : "Sign in" }} </div>
        <div class="signIn signInTwo" @click="register(2)"> {{ $i18n.locale == "zh" ? "注册" : "register" }} </div>
        <!-- <div class="tipTwo" @click="register(2)">
          {{
            $i18n.locale == "zh"
              ? "注册成为超弦会员"
              : "Register as a superstring member"
          }}
        </div> -->
      </div>
    </div>
    <!-- 注册 -->
    <div class="outer" v-if="type == 2">
      <div class="tipOne"> {{ $i18n.locale == "zh" ? "注册" : "register" }} </div>
      <div>
        <div class="input">
          <input type="text" :placeholder="
            $i18n.locale == 'zh'
              ? '请输入您的邮箱（作为登录账号）'
              : 'Please enter your email(As login account)'
          " v-model="email" />
        </div>
        <div class="input">
          <input type="password" :placeholder="
            $i18n.locale == 'zh' ? '请输入密码' : 'Please input a password'
          " v-model="password" />
        </div>
        <div class="input">
          <input type="password" :placeholder="
            $i18n.locale == 'zh'
              ? '请确认密码'
              : 'Please confirm the password'
          " v-model="passwordtwo" />
        </div>
        <div class="signIn" @click="signInLogin(2)"> {{ $i18n.locale == "zh" ? "确认提交" : "Confirm submission" }} </div>
      </div>
    </div>
    <!-- 修改资料 -->
    <div class="outer" v-if="type == 3">
      <div class="tipOne"> {{ $i18n.locale == "zh" ? "完善资料" : "Modify data" }} </div>
      <div>
        <div class="tabNav">
          <div v-for="(item, index) in $t('register.tabbar')" :key="index" @click="navChange(index)" class="navItem">
            <img src="@/assets/img/xuanzhong.png" alt="" v-if="navIndex == index" />
            <img src="@/assets/img/weixuanzhong.png" alt="" v-if="navIndex != index" />
            <div>{{ item }}</div>
          </div>
        </div>
        <div class="identity">
          <!-- <el-select v-model="identity" :popper-append-to-body="false" popper-class="el_select_option" :placeholder="
            $i18n.locale == 'zh'
              ? '请选择您的身份'
              : 'Please select your identity'
          ">
            <el-option v-for="item in userCard" :key="item.card_name" :label="item.card_name" :value="item.id">
            </el-option>
          </el-select> -->
          <div v-for="(item,index) in userCard" :key="index" @click="identityChange(index)">
            <img :src="identityIndex == index ? require('@/assets/img/xuanzhong.png') : require('@/assets/img/weixuanzhonglan.png')" alt="" />
            <div>{{ item.card_name }}</div>
          </div>
        </div>
        <div class="input">
          <input type="text" :placeholder="
            $i18n.locale == 'zh' ? '***先生/女士' : '***Sir/Madam'
          " v-model="account" />
        </div>
        <div class="input">
          <input type="text" :placeholder="
            $i18n.locale == 'zh'
              ? '请输入您的邮箱（作为登录账号）'
              : 'Please enter your email(As login account)'
          " v-model="email" />
        </div>
        <div class="input">
          <input type="password" :placeholder="
            $i18n.locale == 'zh' ? '请输入密码' : 'Please input a password'
          " v-model="password" />
        </div>
        <div class="input">
          <input type="password" :placeholder="
            $i18n.locale == 'zh'
              ? '请确认密码'
              : 'Please confirm the password'
          " v-model="passwordtwo" />
        </div>
        <div class="signInBox">
          <div class="signIn signInTwo" @click="goIndex()"> {{ $i18n.locale == "zh" ? "返回首页" : "Return to homepage" }} </div>
          <div class="signIn" @click="signInLogin(3)"> {{ $i18n.locale == "zh" ? "确认完善" : "Confirm improvement" }} </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：注册
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-24 14:07
 */
import identify from "@/components/identify.vue";
export default {
  components: {
    identify,
  },
  data() {
    return {
      height: "",
      navIndex: 0,
      identity: "",
      identityList: [],
      identityIndex: -1,
      type: "",
      identifyCode: "",
      identifyCodes: "123456789abcdefghijklmnopqrstuvwxyz",
      account: "", // 账号
      email: "", // 邮箱
      password: "", // 密码
      passwordtwo: "",
      captcha: "", // 验证码

      userCard: "", // 身份

      isHover: false,
    };
  },
  watch: {
    height(val) {
      if (!this.timer) {
        this.height = val - this.$(".head")[0].clientHeight;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          that.timer = false;
        }, 400);
      }
    },
  },
  mounted() {
    this.refreshCode();
    // console.log(this.$(".head")[0].clientHeight);
    // console.log(document.documentElement.clientHeight);
    //  - this.$(".head")[0].clientHeight
    this.height = document.documentElement.clientHeight;
    this.type = this.$route.query.type; // 1：登录  2:注册  3:修改资料

    if (this.type == 3) {
      this.getUserInfo();
    }

    this.getUserCard();

    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        that.height = window.fullHeight;
      })();
    };
  },
  methods: {
    /**
     * 会员切换
     * 刘嘉鑫
     * 2022-8-24
     */
    navChange(index) {
      this.navIndex = index;
    },

    /**
     * 企业切换
     * 刘嘉鑫
     * 2023-4-13
     */
     identityChange(index){
      this.identityIndex = index
     },

    /**
     * 注册
     * 刘嘉鑫
     * 2022-8-24
     */
    register(type) {
      this.$router.push({
        path: "register",
        query: {
          type: type,
        },
      });
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
      console.log(this.identifyCode);
    },
    // 生成随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[
          Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
          ];
      }
    },

    /**
     * 登录
     * 刘嘉鑫
     * 2022-8-24
     */
    signInLogin(type) {
      if (this.account == "" && type == 1) {
        this.$notify({
          title: "提醒",
          message: "请输入账号",
          type: "error",
          offset: 80,
        });
        return;
      } else if (this.identityIndex == -1 && this.type == 3) {
        this.$notify({
          title: "提醒",
          message: "请选择身份",
          type: "error",
          offset: 80,
        });
        return;
      } else if (this.account == "" && this.type == 3) {
        this.$notify({
          title: "提醒",
          message: "请输入姓名",
          type: "error",
          offset: 80,
        });
        return;
      } else if (this.email == "" && type == 2) {
        this.$notify({
          title: "提醒",
          message: "请输入邮箱",
          type: "error",
          offset: 80,
        });
        return;
      } else if (this.password == "") {
        this.$notify({
          title: "提醒",
          message: "请输入密码",
          type: "error",
          offset: 80,
        });
        return;
      } else if (this.passwordtwo == "" && type == 2) {
        this.$notify({
          title: "提醒",
          message: "请再次输入密码",
          type: "error",
          offset: 80,
        });
        return;
      } else if (this.passwordtwo != this.password && type == 2) {
        this.$notify({
          title: "提醒",
          message: "两次密码输入不一致",
          type: "error",
          offset: 80,
        });
        return;
      } else if (this.captcha == "" && type == 1) {
        this.$notify({
          title: "提醒",
          message: "请输入验证码",
          type: "error",
          offset: 80,
        });
        return;
      } else if (this.captcha != this.identifyCode && type == 1) {
        this.$notify({
          title: "提醒",
          message: "验证码不正确",
          type: "error",
          offset: 80,
        });
        return;
      } else {
        let url, data;
        if (type == 1) {
          url = "/User/login";
          data = {
            account: this.account,
            password: this.password,
            captcha: this.captcha,
          };
        }
        if (type == 2) {
          url = "/User/register";
          data = {
            type: Number(this.navIndex) + 1, // 类型:1=个人会员,2=单位会员
            card_type: this.identityIndex == -1 ? '' : this.userCard[this.identityIndex].id,
            username: this.account,
            email: this.email,
            password: this.password,
            passwordtwo: this.passwordtwo,
          };
        }
        if (type == 3) {
          url = "/user/profile";
          data = {
            token: localStorage.getItem("token"),
            type: Number(this.navIndex) + 1, // 类型:1=个人会员,2=单位会员
            card_type: this.userCard[this.identityIndex].id,
            username: this.account,
            email: this.email,
            password: this.password,
            passwordtwo: this.passwordtwo,
          };
        }
        this.$request({
          url: url,
          data: data,
        })
          .then((res) => {
            console.log(res);
            localStorage.setItem("token", res.userinfo.token);
            console.log("token", localStorage.getItem("token"));

            // if (this.type == 2) {
              // this.$msgbox({
              //   title: "提示",
              //   message: this.$createElement("p", null, [
              //     this.$createElement("span", null, "您已注册完成，请完善资料"),
              //   ]),
              //   showCancelButton: true,
              //   confirmButtonText: "去完善",
              //   cancelButtonText: "取消",
              //   beforeClose: (action, instance, done) => {
              //     if (action === "confirm") {
              //       this.$router.push({
              //         path: "register",
              //         query: {
              //           type: 3,
              //         },
              //       });
              //       done();
              //     } else {
              //       this.$router.push({
              //         path: "/",
              //       });
              //       done();
              //     }
              //   },
              // });
            // } else
             if (this.type == 3) {
              this.$notify({
                title: "提示",
                message: "修改成功",
                type: "none",
                offset: 80,
              });
              setTimeout(() => {
                this.$router.push({
                  path: "/",
                });
              }, 1500);
            } else {
              this.$router.push({
                path: "/",
              });
            }
          })
          .catch((error) => {
            console.error(error)
            this.$notify({
              title: "提示",
              message: error.msg,
              type: "none",
              offset: 80,
            });
            this.refreshCode()
            this.captcha = ""
            if(this.type == 2){
              if(error.code == 102){
                this.register(1)
              }
            }
          });
      }
    },

    /**
     * 获取信息
     * 刘嘉鑫
     * 2022-8-24
     */
    getUserInfo() {
      this.$request({
        url: "/User/userInfo",
        data: {
          token: localStorage.getItem("token"),
        },
      }).then((res) => {
        console.log("用户信息", res);
        this.account = res.userinfo.username;
        this.email = res.userinfo.email;
        this.navIndex = Number(res.userinfo.type) - 1;
        for(let i in this.userCard){
          if(res.userinfo.card_type == this.userCard[i].id){
            this.identityIndex = i
          }
        }
        // this.identity =
        //   res.userinfo.card_type == 0 ? "" : res.userinfo.card_type;
      });
    },

    /**
     * 获取身份信息
     * 刘嘉鑫
     * 2022-8-25
     */
    getUserCard() {
      this.$request({
        url: "/User/getUsercard",
      }).then((res) => {
        console.log("身份", res);
        this.userCard = res.card_list;
      });
    },

    /**
     * 大图动画效果
     * 刘嘉鑫
     * 2022-8-30
     */
    enters() {
      this.isHover = true; // 当前鼠标悬浮所在的图片隐藏
    },
    leaver() {
      this.isHover = false; // 文字隐藏
    },

    /**
     * 返回首页
     * 刘嘉鑫
     * 2022-9-19
     */
    goIndex() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>
<style>
.identity .el-select {
  width: 89%;
  padding: 0 30px;
}


.identity .el-input__inner {
  width: 100% !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #222222 !important;
  border: none !important;
  background: #f2f2f2 !important;
  padding: 0 !important;
}

.identity .el_select_option {
  min-width: 100% !important;
  left: 0 !important;
}

.identity .el-select-dropdown__item {
  font-size: 18px !important;
  height: 40px !important;
}

.identity .el-input__inner::placeholder {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}
</style>
<style lang='scss' scoped>
.outerCont {
  background: #ffffff;
  display: flex;
  // height: 95vh;
}

.bigPictrue {
  width: 68%;
  height: 100%;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  .itemHoverCont {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.2s ease-out 0s;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.outer {
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 79px 120px 0;

  >div {
    width: 100%;
  }

  .tipOne {
    font-size: 30px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #1a2a60;
    margin-bottom: 60px;
  }

  .tabNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .navItem {
      display: flex;
      align-items: center;
      margin-right: 45px;

      >img {
        width: 40px;
        height: 40px;
        margin-right: 19px;
      }

      >div {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
      }
    }
  }

  .identity {
    background: #f2f2f2;
    border-radius: 6px;
    padding: 10px 20px 0;
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    >div{
      display: flex;
      align-items: center;
      width: 30%;
      margin-bottom: 10px;

      >img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }

      >div {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
      }
    }

    >div:nth-child(2),
    >div:nth-child(5){
      width: 40% !important;
    }
  }

  .input {
    background: #f2f2f2;
    border-radius: 6px;
    padding: 16px 29px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 30px;
    }

    input {
      width: 100%;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #222222;
      border: none;
      background: transparent;
    }

    input::placeholder {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }

    input:focus {
      outline: none;
    }

    .captcha {
      width: 120px;
      height: 42px;
    }
  }

  .inputTwo {
    padding: 8px 19px 8px 29px;
  }

  .signInBox{
    display: flex;
    align-items: center;
    justify-content: space-between;

    >div{
      width: 48% !important;
    }
    
.signInTwo{
  margin-top: 10px !important;
}
  }

  .signIn {
    margin-top: 10px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    padding: 21px 0;
    text-align: center;
    background: #1a2960;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
  }

  .signInTwo {
    color: #1a2960 !important;
    background: #f2f2f2 !important;
    margin-top: 30px;
  }

  .tipTwo {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #1a2960;
    margin-top: 80px;
    padding: 0 6px;
    cursor: pointer;
  }
}
</style>